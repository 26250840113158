import React, { useState } from 'react';
import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from './pages/auth/Login';
import Logout from './pages/auth/Logout';
import Dashboard from './pages/dashboard/Dashboard';
import Sidebar from './components/sidebar/Sidebar';
import Footer from './components/footer/Footer';
import Header from './components/header/Header';
import EditProfile from './pages/profile/Profile';
import ChangePassword from './pages/auth/ChangePassword';
import Contact from './pages/static/Contact';
import AboutUs from './pages/static/AboutUs';
import PrivacyPolicy from './pages/static/PrivacyPolicy';
import TermsCondition from './pages/static/Terms&Condition';
import FaqPage from './pages/static/faq/FaqPage';
import UsersList from './pages/user/user_list';
import UsersFeedbackList from './pages/user/users_feedback_list';
import UserView from './pages/user/user_view';
import SettersList from './pages/user/setter_list';
import ClosersList from './pages/user/closer_list';
import AddIndustrail from './pages/industrial/industrial_add';
import IndustryList from './pages/industrial/industrial_list';
import FaqList from './pages/static/faq/FaqList';
import AddFaq from './pages/static/faq/AddFaq';
import EditFaq from './pages/static/faq/EditFaq';
import SetterView from './pages/user/setter_view';
import CloserView from './pages/user/closer_view';
import LeadsList from './pages/lead/lead_list';
import LeadView from './pages/lead/lead_view';
import LeadEdit from './pages/lead/lead_edit';
import ToBeVerifiedList from './pages/lead/to_be_verified_lead_list';
import InReviewList from './pages/lead/in_review_lead_list';
import TeBeVerifiedView from './pages/lead/to_be_verified_lead_view';
import InReviewView from './pages/lead/in_review_lead_view';
import ToBeVerifiedLeadEdit from './pages/lead/to_be_verified_lead_edit';
import InReviewLeadEdit from './pages/lead/in_review_lead_edit';
import ChatUserList from './pages/user_support/chat_user_list';
import ChatPage from './pages/user_support/chat_page';
import AboutUsPage from './pages/static/AboutUsPage';
import PrivacyPolicyPage from './pages/static/PrivacyPolicyPage';
import TermsConditionPage from './pages/static/Terms&ConditionPage';
import ManageCloserTutorials from './pages/tutorial/manage_closer_tutorials';
import ManageSetterTutorials from './pages/tutorial/manage_setter_tutorials';
import Category from './pages/tutorial/category';
import LeadQuestionList from './pages/user_support/lead_question_list';
import LeadReviewList from './pages/lead_reviews/lead_review_list';
import EarningList from './pages/earning/earning_list';
import LeadDetails from './pages/earning/lead_view';
import GlobalCommission from './pages/setting/global_commission';
import UrlPage from './pages/lead/url_page';
import PageNotFound from './pages/PageNotFound';

function App() {
  
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  function toggleSidebar() {
      setIsSidebarOpen(!isSidebarOpen);
  }

  if (!localStorage.getItem("AisLogin", false)) {
    return (
      <div className="App">
        <BrowserRouter basename={'/admin'}>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="*" element={<Login />} />
            <Route path='/aboutus-page' element={<AboutUsPage />} />
            <Route path='/privacy-policy-page' element={<PrivacyPolicyPage />} />
            <Route path='/terms-condition-page' element={<TermsConditionPage />} />
            <Route path='/faq' element={<FaqPage />} />
          </Routes>
        </BrowserRouter>
      </div>
    )
  } else {
    return (
      <div className="App">
        <BrowserRouter basename={'/admin'}>
          <Header isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
          <Sidebar isSidebarOpen={isSidebarOpen} />
          <Routes>
            <Route path="*" element={<PageNotFound />} />
            <Route path="/" element={<Dashboard />} />
            <Route path="/usersList" element={<UsersList />} />
            <Route path="/settersList" element={<SettersList />} />
            <Route path="/closersList" element={<ClosersList />} />
            <Route path="/setterView/:id" element={<SetterView />} />
            <Route path="/closerView/:id" element={<CloserView />} />
            <Route path="/leadsList" element={<LeadsList />} />
            <Route path="/leadView/:id" element={<LeadView />} />
            <Route path="/leadEdit/:id" element={<LeadEdit />} />

            <Route path="/toBeVerifiedList" element={<ToBeVerifiedList />} />
            <Route path="/toBeVerifiedView/:id" element={<TeBeVerifiedView />} />
            <Route path="/toBeVerifiedEdit/:id" element={<ToBeVerifiedLeadEdit />} />

            <Route path="/inReviewList" element={<InReviewList />} />
            <Route path="/inReviewView/:id" element={<InReviewView />} />
            <Route path="/inReviewEdit/:id" element={<InReviewLeadEdit />} />
            <Route path="/feedback/:id" element={<UsersFeedbackList />} />
            <Route path='/user/:id' element={<UserView />} />
            <Route path="/changePassword" element={<ChangePassword />} />
            <Route path="/editProfile" element={<EditProfile />} />
            <Route path='/contactUs' element={<Contact />} />
            <Route path='/about' element={<AboutUs />} />
            <Route path='/policy' element={<PrivacyPolicy />} />
            <Route path='/terms' element={<TermsCondition />} />
            <Route path="/logout" element={<Logout />} />
            <Route path='/faqList' element={<FaqList />} />
            <Route path='/faqList/addfaq' element={<AddFaq />} />
            <Route path='/faqList/editfaq/:id' element={<EditFaq />} />
            <Route path='/add-industrail' element={<AddIndustrail />} />
            <Route path='/view-industrail/:id' element={<AddIndustrail />} />
            <Route path='/edit-industrail/:id' element={<AddIndustrail />} />
            <Route path='/industrailList' element={<IndustryList />} />

            <Route path='/chatuserlist' element={<ChatUserList />} />
            <Route path='/chat' element={<ChatPage />} />
            <Route path='/lead-question' element={<LeadQuestionList />} />

            <Route path='/category' element={<Category />} />
            <Route path='/closertutorial' element={<ManageCloserTutorials />} />
            <Route path='/settertutorial' element={<ManageSetterTutorials />} />

            <Route path='/lead-review-list' element={<LeadReviewList />} />

            <Route path='/earning-list' element={<EarningList />} />
            <Route path="/lead-details/:id" element={<LeadDetails />} />
            <Route path="/commission" element={<GlobalCommission />} />
            <Route path="/urlpage/:id" element={<UrlPage />} />

          </Routes>
          <Footer />
        </BrowserRouter>
      </div>
    )
  }

}

export default App;
