import React, { useEffect, useState } from 'react'
import {  getIndustrylist, deleteIndustry, activeInIndustry } from '../../api/apiHandler';
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import CryptoJS from 'crypto-js';
import $ from "jquery";
import { bodyEncryption } from '../common/RedirectPathMange';

import FeatherIcon from "feather-icons-react";
// import Tooltip from '@material-ui/core/Tooltip';
import Tooltip from '@mui/material/Tooltip';
// import IconButton from '@material-ui/core/IconButton';
import IconButton from '@mui/material/IconButton';
import Swal from 'sweetalert2'
import { renderToStaticMarkup } from 'react-dom/server'
import { Link, useNavigate } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import ReactDOM from 'react-dom';
import { set } from 'react-hook-form';

function IndustryList() {
    const navigate = useNavigate();
    const [industrysList, setIndustryList] = useState([]);
    const [totalRecordCount, setTotalRecordCount] = useState(0)
    const [pageNo, setPageNo] = useState(1);
    const [searchQuery, setSearchQuery] = useState('');
    const [loader, setLoader] = useState(true);
    const itemsPerPage = 10;

    useEffect(() => {
        
        if(searchQuery === ''){
            Swal.fire({
                title: 'Please wait...',
                didOpen: () => {
                    Swal.showLoading();
                }
            });
        }

        getIndustrylist({ "page": pageNo, "record_count": parseInt(process.env.REACT_APP_RECORD_COUNT), 'search_text': searchQuery }).then((response) => {
            response = JSON.parse(response)
            // console.log(response.data,"responce data")

            if (response.code === 200) {
                Swal.close();
                setLoader(false);
                setIndustryList(response.data.Industry)
                setTotalRecordCount(Math.ceil(response.data.total_record_count / process.env.REACT_APP_RECORD_COUNT))
            } else {
                Swal.close();
                setLoader(false);
                setIndustryList([]);
            }
        })

        // Swal.close();
    }, [searchQuery, pageNo]);


    const handlePageClick = (event) => {
        setPageNo(event.selected + 1)
    };

    const viewIndustryDetails = (item) => {
        var encId = encodeURIComponent(bodyEncryption((item.id).toString()))
        navigate(`/view-industrail/${encId}`);
    }

    const viewEditDetails = (item) => {
        var encId = encodeURIComponent(bodyEncryption((item.id).toString()))
        navigate(`/edit-industrail/${encId}`);
    }

    const showMessage = (msg) => {
        Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: msg,
            toast: true,
            showConfirmButton: false,
            timer: 1500
        })
    }

    function deleteIndustryIdWise(industryId) {

        Swal.fire({
            title: 'Are you sure you want to delete?',
            text: "You won't be able to revert this!",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#02C9D6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                // console.log("industryId" + industryId)
                deleteIndustry({ "industry_id": industryId }).then((response) => {
                    response = JSON.parse(response)
                    if (response.code === 200) {
                        // $('#mangeUser').DataTable().row("#industryId" + industryId).remove().draw(false);
                        showMessage(response.message);
                        // Update industry list after deletion
                        setIndustryList(prevIndustrysList => prevIndustrysList.filter(industry => industry.id !== industryId));
                    }
                });
            }
        })
    }

    const handleSearch = (event) => {
        const query = event.target.value;
        setSearchQuery(query);
    }

    function changeStatus(industryId, item, position) {

        var status = (item.is_active == "1") ? "0" : "1"

        activeInIndustry({ "id": industryId }).then((response) => {
            response = JSON.parse(response)
            if (response.code == 200) {
                showMessage(response.message);
                industrysList[position].is_active = status
                var data = renderToStaticMarkup(renderStatusUiText(industrysList[position])).replace("MuiBox-root css-4u9rcm", "MuiBox-root css-k0gowo").replace("MuiBox-root css-1oewkz4", "MuiBox-root css-lv896x")
                // $('#mangeUser').DataTable().row("#industryId" + industryId).cell("#industryStatus" + industryId).data(data).draw(false);
                getIndustrylist({ "page": pageNo, "record_count": parseInt(process.env.REACT_APP_RECORD_COUNT), 'search_text': searchQuery }).then((response) => {
                response = JSON.parse(response)
                    // console.log(response.data,"responce data")

                    if (response.code === 200) {
                       // Swal.close();
                        setLoader(false);
                        setIndustryList(response.data.Industry)
                        setTotalRecordCount(Math.ceil(response.data.total_record_count / process.env.REACT_APP_RECORD_COUNT))
                    } else {
                        setLoader(false);
                        setIndustryList([]);
                    }
                })
            }
        });
    }

    function renderStatusUiText(item) {
        return (
            item.is_active == "1" ? <span style={{ color: 'green' }}>Active</span> : <span style={{ color: 'red' }}>Inactive</span>
            )
        }

    return (
        <>
            <main>
                <div className='content-page mt-3'>
                    <div className='content'>
                        <div className='container-fluid'>
                            <div className='cal'>
                                <div className="col-sm-12">
                                    <div className="page-title-box">
                                        <h4 className="page-title">Industrial</h4>
                                    </div>
                                </div>
                                <div className="search-bar">
                                    <input
                                        type="text"
                                        placeholder="Search..."
                                        value={searchQuery}
                                        onChange={handleSearch}
                                    />
                                    <Link
                                        to={"/add-industrail"}
                                        className='mx-2 btn btn-outline-primary'
                                        >
                                            {/* &nbsp; Add Industry */}
                                            <i class="fa-solid fa-plus mr-1"></i><span>Add Industry</span>
                                    </Link>
                                    {/* <button className='fas fa-plus mx-2 btn btn-outline-primary'> Add Industry</button> */}
                                </div>
                                {loader === false ?
                                <>
                                    <div className="table-responsive">
                                        <table id="mangeUser" className="table table-hover dataTable js-exportable">
                                            <thead>
                                                <tr>
                                                    <th>ID</th>
                                                    <th>Industry Name</th>
                                                    {/* <th>Fields Name</th>
                                                    <th>Fields Type</th>
                                                    <th>Setter Manage</th> */}
                                                    <th>Status</th>
                                                    <th style={{ textAlign: "center" }}>Status Action</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>

                                            {industrysList.length > 0 ? (
                                            <tbody>
                                                {
                                                    industrysList && industrysList.map((item, index) => {
                                                        const displayIndex = ((pageNo * itemsPerPage) - 10) + index + 1;
                                                        return (
                                                            <tr id={item.id} key={index}>
                                                                <td>
                                                                    {displayIndex}
                                                                    {/* {index+1} */}
                                                                </td>
                                                                <td>
                                                                    {item.industry_name}
                                                                </td>
                                                                {/* <td >
                                                                    {item.fields_name}
                                                                </td>
                                                                <td >
                                                                    {item.field_type}
                                                                </td>
                                                                <td >
                                                                    {item.setter_manage}
                                                                </td> */}
                                                                
                                                                {/* <td >
                                                                    {item.is_active === '0' ? <b><span style={{ "color": "Red" }}>Inactive</span></b> : <b><span style={{ "color": "Green" }}>Active</span></b>}
                                                                </td> */}

                                                                <td id={"industryStatus" + item.id}>
                                                                    {renderStatusUiText(item)}
                                                                </td>

                                                                {/* <td id={"industryStatus" + item.id} style={{ textAlign: "center" }}>
                                                                    {renderStatusUiText(item)}
                                                                </td> */}
                                                                <td id={"industryStatus" + item.id} style={{ textAlign: "center" }}>
                                                                    <Tooltip title="Change Status">
                                                                        <IconButton onClick={() => { changeStatus(item.id, item, index) }}>
                                                                            <FeatherIcon icon="repeat" width="18" />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </td>
                                                                <td>
                                                                    <Tooltip title="View">
                                                                        <IconButton onClick={() => { viewIndustryDetails(item) }}>
                                                                            <FeatherIcon icon="eye" width="18" />
                                                                        </IconButton>
                                                                    </Tooltip>

                                                                    <Tooltip title="Edit">
                                                                        <IconButton onClick={() => { viewEditDetails(item) }}>
                                                                            <FeatherIcon icon="edit" width="18" />
                                                                        </IconButton>
                                                                    </Tooltip>

                                                                    <Tooltip title="Delete">
                                                                        <IconButton onClick={() => { deleteIndustryIdWise(item.id) }}>
                                                                            <FeatherIcon icon="trash-2" width="18" color="red" />
                                                                        </IconButton>
                                                                    </Tooltip>

                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                            ) : (
                                                industrysList.length === 0 && <h3 className='mt-3'>No Industry data available.</h3>                                
                                            )}                                    
                                        </table>
                                    </div>
                                    <ReactPaginate
                                        breakLabel="..."
                                        nextLabel=" > "
                                        onPageChange={handlePageClick}
                                        // pageRangeDisplayed={pageCount}
                                        pageCount={totalRecordCount}
                                        previousLabel=" < "
                                        renderOnZeroPageCount={null}
                                        containerClassName="pagination"
                                        pageLinkClassName="page-link"
                                        previousLinkClassName="page-link"
                                        nextLinkClassName="page-link"
                                        disabledClassName="disabled"
                                        activeClassName="active"
                                        activeLinkClassName="active-link"
                                    />
                                </>
                                :''}
                            </div>
                        </div>
                    </div>
                </div >
            </main>
        </>

    );
}

export default IndustryList;