import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { getManagePage, editManagePage } from '../../api/apiHandler';
import Swal from 'sweetalert2'
import ReactQuill from 'react-quill';
import "react-quill/dist/quill.snow.css";


function PrivacyPolicy() {

    const [text, setText] = useState(null);
    const navigate = useNavigate();
    useEffect(() => {

        getManagePage({}).then((response) => {
            response = JSON.parse(response)
            if (response.code === 200) {
                // console.log(response.data,"responce data")
                setText(response.data[1].content);
            }
        });
    }, []);

    function redirect() {
        sessionStorage.setItem("flag",0)
        navigate("/")
    }

    function updateData() {
        editManagePage({ "title": "Privacy Policy", "tag": "PRIVACY_POLICY", "content": text }).then((response) => {
            response = JSON.parse(response)
            if (response.code === 200) {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.message,
                    toast: true,
                    showConfirmButton: false,
                    timer: 2000
                })
                setTimeout(() => {
                    navigate("/")
                }, 2000)
            } else {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: response.message,
                    toast: true,
                    showConfirmButton: false,
                    timer: 2000
                })
            }
        })
    }

    if (text == null) return <> </>

    return (
        <div className='content-page mt-5'>
            <div className='content'>
                <div className='container-fluid' >
                    <div className='cal col-xs-11 col-md-11 col-sm-11'>
                        <div className='about mb-3'>
                            <h3>Privacy Policy</h3>
                        </div>
                        <ReactQuill value={text} style={{ height: 300 }} onChange={(value) => { setText(value) }} placeholder="Type here..." />
                        <br /><br /><br />
                        <button type="submit" className="btn btn-primary waves-effect waves-light m-2" onClick={updateData}> Save </button>
                        <button className="btn btn-danger" onClick={redirect} > Cancel </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PrivacyPolicy;