import React, { useEffect, useRef, useState } from 'react'
import { getUserLeadList, getUsersList } from '../../api/apiHandler';
import $ from "jquery";
import FeatherIcon from "feather-icons-react";
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Swal from 'sweetalert2'
import { renderToStaticMarkup } from 'react-dom/server'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { bodyDecryption } from '../common/RedirectPathMange';
import moment from 'moment';

function SetterView() {
    let { id } = useParams();
    var decId = decodeURIComponent(bodyDecryption(id))

    const [userDetails, setUserDetails] = useState(null)
    // console.log('userDetails: ', userDetails);
    const [pageNo, setPageNo] = useState(1);

    const [leadDetails, setLeadDetails] = useState(null)
    const [loader, setLoader] = useState(true);

    useEffect(() => {
        Swal.fire({
            title: 'Please wait...',
            didOpen: () => {
                Swal.showLoading();
            }
        });
        if (id != undefined) {
            getUsersList({ "user_id": decId,"page": pageNo, "record_count": parseInt(process.env.REACT_APP_RECORD_COUNT) }).then((res) => {
                res = JSON.parse(res)
                if (res.code == 200) {
                    // console.log("hello",res.data)
                    // console.log("---hello",res.data.users[0].email)
                    Swal.close();
                    setLoader(false);
                    setUserDetails(res.data.users[0])
                } else {
                    Swal.close();
                    setLoader(false);
                    setUserDetails([])
                }
            });

            getUserLeadList({ "user_id": decId }).then((res) => {
                res = JSON.parse(res)
                if (res.code == 200) {
                    setLeadDetails(res.data.Leads) 
                } else {
                    setLeadDetails([])
                }
            });
        }
    }, [id])

    function handleDownload(url) {
        const anchor = document.createElement('a');
        anchor.href = url;
        anchor.target = '_blank';
        anchor.download = url.substring(url.lastIndexOf('/') + 1);
        anchor.style.display = 'none';
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
    }

    const [currentAudio, setCurrentAudio] = useState(null);
    const audioRef = useRef(null);

    const handlePlay = (audioElement) => {
        if (currentAudio && currentAudio !== audioElement) {
            currentAudio.pause();
        }
        setCurrentAudio(audioElement);
    };
    
    if (userDetails == null) return <> </>

    return (
        <>
            <div className='content-page mt-5'>
                <div className='content'>
                    <div className='container-fluid'>
                        <div className='cal'>
                            <div className="row clearfix d-flex justify-content-center text-center" >
                                {loader === false ?
                                <div className="col-lg-9 col-md-12">
                                    <div className="header l-slategray">
                                        <h4 className="m-t-10">{userDetails.firstname} {userDetails.lastname}</h4>
                                    </div>
                                    <div className="member-img">
                                        {/* <img src='https://www.pngitem.com/pimgs/m/508-5087336_person-man-user-account-profile-employee-profile-template.png' className="rounded-circle" alt="profile-image" style={{ width: 200, height: 200 }} /> */}
                                        <img src={userDetails.profile_picture != undefined && userDetails.profile_picture != null ? userDetails.profile_picture : 'https://www.pngitem.com/pimgs/m/508-5087336_person-man-user-account-profile-employee-profile-template.png'} className="rounded-circle" alt="profile-image" style={{width: 200, height: 200}} />
                                    </div>
                                    <div className="body">
                                        <hr />
                                        <div className="row">
                                        <div className="col-4">
                                                <h5>Username</h5>
                                                <p style={{ fontSize: '15px' }}>{userDetails.username}</p>
                                            </div>
                                            <div className="col-4">
                                                <h5>Email</h5>
                                                <p style={{ fontSize: '15px' }}>{userDetails.email}</p>
                                            </div>
                                            <div className="col-4">
                                                <h5>Phone Number</h5>
                                                <p style={{ fontSize: '15px' }}>{userDetails.country_code} {userDetails.phone_number}</p>
                                            </div>
                                        </div>
                                        <hr />
                                        <div className="row">
                                            <div className="col-4">
                                                <h5>Preferred Industries</h5>
                                                {userDetails.preferred_industries == null ? (
                                                    <p style={{ fontSize: '15px' }}>No data</p>
                                                    ) : (
                                                    <>
                                                        <p style={{ fontSize: '15px' }}>{userDetails.preferred_industries}</p>
                                                    </>
                                                )}
                                            </div>
                                            <div className="col-4">
                                                <h5>Company Name</h5>
                                                {userDetails.company_name == null ? (
                                                    <p style={{ fontSize: '15px' }}>No data</p>
                                                    ) : (
                                                    <>
                                                        <p style={{ fontSize: '15px' }}>{userDetails.company_name}</p>
                                                    </>
                                                )}
                                            </div>
                                            {/* <div className="col-4">
                                                <h5>Bank Name</h5>
                                                {userDetails.bank_name == null ? (
                                                    <p style={{ fontSize: '15px' }}>No data</p>
                                                    ) : (
                                                        <>
                                                        <p style={{ fontSize: '15px' }}>{userDetails.bank_name}</p>
                                                    </>
                                                )}
                                            </div> */}
                                            <div className="col-4">
                                                <h5>Agreement</h5>
                                                {userDetails.signature == null ? (
                                                    <p style={{ fontSize: '15px' }}>No data</p>
                                                    ) : (
                                                    <>
                                                        <a href={userDetails.signature} target="_blank">
                                                            <p style={{fontSize:'15px',overflowWrap: 'break-word'}} >{userDetails.signature.split("/")[4]}</p>
                                                        </a>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                        <hr />
                                        {/* <div className="row">
                                            <div className="col-4">
                                                <h5>Account Number</h5>
                                                {userDetails.account_number == null ? (
                                                    <p style={{ fontSize: '15px' }}>No data</p>
                                                    ) : (
                                                    <>
                                                        <p style={{ fontSize: '15px' }}>{userDetails.account_number}</p>
                                                    </>
                                                )}
                                            </div>
                                            <div className="col-4">
                                                <h5>Routing Number</h5>
                                                {userDetails.routing_number == null ? (
                                                    <p style={{ fontSize: '15px' }}>No data</p>
                                                    ) : (
                                                    <>
                                                        <p style={{ fontSize: '15px' }}>{userDetails.routing_number}</p>
                                                    </>
                                                )}
                                            </div>
                                            <div className="col-4">
                                                <h5>Account Holder Name</h5>
                                                {userDetails.account_holder_name == null ? (
                                                    <p style={{ fontSize: '15px' }}>No data</p>
                                                    ) : (
                                                    <>
                                                        <p style={{ fontSize: '15px' }}>{userDetails.account_holder_name}</p>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                        <hr /> */}
                                        <div className="row">
                                            
                                            <div className="col-4">
                                                <h5>Status</h5>
                                                <p style={{ fontSize: '14px' }}>{userDetails.is_active == '0' ? "🔴 Inactive" : "🟢 Active"}</p>
                                            </div>
                                            <div className="col-4">
                                                <h5>Created</h5>
                                                <p style={{ fontSize: '15px' }}>{ moment(userDetails.createdAt).local().format('YYYY-MM-DD hh:mm A') }</p>
                                            </div>
                                        </div>

                                        {leadDetails?.length > 0 ? (
                                            <>
                                                {leadDetails && leadDetails.map((leadDetails, index)=>(
                                                    <>
                                                     {/* <div className="row clearfix d-flex justify-content-center text-center" > */}
                                                         <div className="col-lg-12 col-md-12 mt-5">
                                                            <h2>Lead Details</h2>

                                                            <div className="body">
                                                                <hr />
                                                                <div className="row">
                                                                    <div className="col-4">
                                                                        <h5>Industry</h5>                                                
                                                                        {leadDetails.industry == null ? (
                                                                            <p style={{ fontSize: '15px' }}>No data</p>
                                                                            ) : (
                                                                                <>
                                                                                <p style={{ fontSize: '15px' }}>{leadDetails.industry}</p>
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                    <div className="col-4">
                                                                        <h5>Category</h5>
                                                                        <p style={{ fontSize: '15px' }}>{leadDetails.category}</p>
                                                                    </div>
                                                                    <div className="col-4">
                                                                        <h5>Name</h5>
                                                                        {leadDetails.name.length == 0 ? (
                                                                            <p style={{ fontSize: '15px' }}>No data</p>
                                                                            ) : (
                                                                                <>
                                                                                <p style={{ fontSize: '15px' }}>{leadDetails.name}</p>
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                                <hr />
                                                                <div className="row">
                                                                    <div className="col-4">
                                                                        <h5>Email</h5>
                                                                        {leadDetails.email.length == 0 ? (
                                                                            <p style={{ fontSize: '15px' }}>No data</p>
                                                                            ) : (
                                                                                <>
                                                                                <p style={{ fontSize: '15px' }}>{leadDetails.email}</p>
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                    <div className="col-4">
                                                                        <h5>Phone Number</h5>
                                                                        {leadDetails.phone_number.length == 0 ? (
                                                                            <p style={{ fontSize: '15px' }}>No data</p>
                                                                            ) : (
                                                                                <>
                                                                                <p style={{ fontSize: '15px' }}>{leadDetails.phone_number}</p>
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                    <div className="col-4">
                                                                        <h5>Secondary Phone Number</h5>
                                                                        {leadDetails.secondary_phone_number == null ? (
                                                                            <p style={{ fontSize: '15px' }}>No data</p>
                                                                            ) : (
                                                                                <>
                                                                                <p style={{ fontSize: '15px' }}>{leadDetails.secondary_phone_number}</p>
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                                <hr />
                                                                <div className="row">
                                                                    <div className="col-4">
                                                                        <h5>Is Virtual</h5>
                                                                        <p style={{ fontSize: '15px' }}>{leadDetails.is_virtual == '0' ? "Not-virtual" : "Virtual"}</p>
                                                                    </div>
                                                                    <div className="col-4">
                                                                        <h5>Appointment Date & Time</h5>
                                                                        <p style={{ fontSize: '15px' }}>{ moment(leadDetails.appointment_date).local().format('YYYY-MM-DD hh:mm A') }</p>
                                                                    </div>
                                                                    <div className="col-4">
                                                                        <h5>Lead Status</h5>
                                                                        {leadDetails.status == null ? (
                                                                            <p style={{ fontSize: '15px' }}>No data</p>
                                                                            ) : (
                                                                            <>
                                                                                <p style={{ fontSize: '15px' }}>{leadDetails.status}</p>
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                                <hr />
                                                                
                                                                <div className="row">
                                                                    <div className="col-4">
                                                                        <h5>Lead Main Status</h5>
                                                                        {leadDetails.lead_main_status == null ? (
                                                                            <p style={{ fontSize: '15px' }}>No data</p>
                                                                            ) : (
                                                                            <>
                                                                                <p style={{ fontSize: '15px' }}>{leadDetails.lead_main_status}</p>
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                    <div className="col-4">
                                                                        <h5>Priority</h5>
                                                                        {leadDetails.priority == null ? (
                                                                            <p style={{ fontSize: '15px' }}>No data</p>
                                                                            ) : (
                                                                                <>
                                                                                <p style={{ fontSize: '15px' }}>{leadDetails.priority}</p>
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                    <div className="col-4">
                                                                        <h5>Badges</h5>
                                                                        {leadDetails.badges == null ? (
                                                                            <p style={{ fontSize: '15px' }}>No data</p>
                                                                            ) : (
                                                                                <>
                                                                                <p style={{ fontSize: '15px' }}>{leadDetails.badges}</p>
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                                <hr />
                                                                <div className="row">
                                                                    <div className="col-4">
                                                                        <h5>Address</h5>
                                                                        <p style={{ fontSize: '15px' }}>{leadDetails.address}</p>
                                                                    </div>
                                                                    <div className="col-4">
                                                                        <h5>Avg Rating</h5>
                                                                        {leadDetails.avg_rating == null ? (
                                                                            <p style={{ fontSize: '15px' }}>No data</p>
                                                                            ) : (
                                                                            <>
                                                                                <p style={{ fontSize: '15px' }}>{leadDetails.avg_rating}</p>
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                    <div className="col-4">
                                                                        <h5>Total Review</h5>
                                                                        {leadDetails.total_review == null ? (
                                                                            <p style={{ fontSize: '15px' }}>No data</p>
                                                                            ) : (
                                                                            <>
                                                                                <p style={{ fontSize: '15px' }}>{leadDetails.total_review}</p>
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                                <hr />

                                                                <div className="row">
                                                                    <div className="col-4">
                                                                        <h5>Show Contact Info</h5>
                                                                        <p style={{ fontSize: '15px' }}>{leadDetails.is_showcontactinfo == 0 ? "No" : "Yes"}</p>
                                                                    </div>
                                                                    <div className="col-4">
                                                                        <h5>Status</h5>
                                                                        <p style={{ fontSize: '14px' }}>{leadDetails.is_active == '0' ? "🔴 Inactive" : "🟢 Active"}</p>
                                                                    </div>
                                                                    <div className="col-4">
                                                                        <h5>Created</h5>
                                                                        <p style={{ fontSize: '15px' }}>{ moment(leadDetails.createdAt).local().format('YYYY-MM-DD hh:mm A') }</p>
                                                                    </div>
                                                                </div>
                                                                <hr />

                                                                <div className="row">
                                                                    <div className="col-12 text-left">
                                                                        <h5>Lead Notes</h5>
                                                                        {leadDetails.lead_documents.length == 0 ? (
                                                                            <p style={{ fontSize: '15px' }}>No data</p>
                                                                            ) : (
                                                                            <>
                                                                                <ul className="list-group">
                                                                                    {leadDetails.lead_notes_data.map((note, index) => (
                                                                                        <li key={index} className="list-group-item">
                                                                                            <div className="note-content">
                                                                                                <span className="note-index"><b>{index + 1}.</b></span>
                                                                                                <span className="note-text mx-2" style={{ fontWeight: 'bold' }}>{note.title}</span>
                                                                                                <div className='clearfix'></div>
                                                                                                <span className="note-text mx-3">{note.notes}</span>
                                                                                            </div>
                                                                                        </li>
                                                                                    ))}
                                                                                </ul>
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                </div>

                                                                <div className="row">
                                                                    <div className="col-12 text-left">
                                                                        <h5>Lead Images</h5>
                                                                        {leadDetails.lead_images.length == 0 ? (
                                                                            <p style={{ fontSize: '15px' }}>No data</p>
                                                                            ) : (
                                                                            <>
                                                                                <ul className="list-group">
                                                                                    <div class="row">
                                                                                        {leadDetails.lead_images.map((image, index) => (
                                                                                            <li key={index} className="list-group-item">
                                                                                            <div class="col-4 note-content"> 
                                                                                                <div style={{ position: 'relative', display: 'inline-block' }}>
                                                                                                    <img
                                                                                                        src={image.images ? image.images : 'https://www.pngitem.com/pimgs/m/508-5087336_person-man-user-account-profile-employee-profile-template.png'}
                                                                                                        className=""
                                                                                                        alt="lead-image"
                                                                                                        style={{ width: 200, height: 200 }}
                                                                                                    />
                                                                                                    <Tooltip title="Download" placement="top">
                                                                                                        <IconButton style={{ position: 'absolute', top: 0, right: 0 }} onClick={() => handleDownload(image.images)}>
                                                                                                            <FeatherIcon icon="download" width="18" color="blue" />
                                                                                                        </IconButton>
                                                                                                    </Tooltip>
                                                                                                </div>
                                                                                            </div>
                                                                                            </li>
                                                                                        ))}
                                                                                    </div>
                                                                                </ul>      
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                </div>

                                                                <div className="row">
                                                                    <div className="col-12 text-left">
                                                                        <h5>Lead Recordings</h5>

                                                                        {leadDetails.lead_recordings.length == 0 ? (
                                                                            <p style={{ fontSize: '15px' }}>No data</p>
                                                                            ) : (
                                                                            <>
                                                                                <ul className="list-group">
                                                                                    {leadDetails.lead_recordings.map((recording, index) => (
                                                                                        <li key={index} className="list-group-item">
                                                                                            <div className="note-content">
                                                                                                <div className='row'>
                                                                                                    <span className="note-index"><b>{index + 1}.</b></span>
                                                                                                    {/* <audio src={recording.recordings_name} controls/> */}
                                                                                                    <audio
                                                                                                        ref={audioRef}
                                                                                                        src={recording.recordings_name}
                                                                                                        // src={`${process.env.REACT_APP_AWS_S3_URL}${process.env.REACT_APP_LEAD_RECORDINGS}/${recording.recordings_name}`}
                                                                                                        controls
                                                                                                        onPlay={(e) => handlePlay(e.target)}
                                                                                                    />
                                                                                                    <Tooltip title="Download">
                                                                                                        <IconButton onClick={() => handleDownload(recording.recordings_name)}>
                                                                                                            <a href={recording.recordings_name} target='_blank' download>
                                                                                                                <FeatherIcon icon="download" width="18" color="blue" />
                                                                                                            </a>
                                                                                                        </IconButton>
                                                                                                    </Tooltip>
                                                                                                </div>

                                                                                                <div className='row'>
                                                                                                    <span><strong>{recording.recording_show}:</strong></span>
                                                                                                    <span className="note-text mx-2">{recording.dummy_recording}</span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </li>
                                                                                    ))}
                                                                                </ul>
                                                                            </>
                                                                            )}
                                                                    </div>
                                                                </div>

                                                                <div className="row">
                                                                    <div className="col-12 text-left">
                                                                        <h5>Lead Documents</h5> 
                                                                        {leadDetails.lead_documents.length == 0 ? (
                                                                            <p style={{ fontSize: '15px' }}>No data</p>
                                                                            ) : (
                                                                            <>
                                                                                <ul className="list-group">
                                                                                    {leadDetails.lead_documents.map((document, index) => (
                                                                                        <li key={index} className="list-group-item">
                                                                                            <div className="note-content">
                                                                                                <span className="note-index"><b>{index + 1}.</b></span>
                                                                                                <a href={document.documents} target='_blank' className="note-text mx-2">{document.dummy_documents}</a>
                                                                                                <Tooltip title="Download">
                                                                                                    <IconButton onClick={() => handleDownload(document.documents)}>
                                                                                                        <a href={document.documents} target='_blank' download>
                                                                                                            <FeatherIcon icon="download" width="18" color="blue" />
                                                                                                        </a>
                                                                                                    </IconButton>
                                                                                                </Tooltip>
                                                                                            </div>
                                                                                        </li>
                                                                                    ))}
                                                                                </ul>
                                                                                </>
                                                                            )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            
                                                         </div>
                                                     {/* </div> */}
                                                    </>
                                                ))}
                                            </>
                                            ) : (
                                            <>    
                                            </>
                                        )}

                                        <br /><br /><br />
                                    </div>
                                    <Link to='/settersList' className="btn btn-primary btn-lg common_hover">
                                        <i className="mdi mdi-chevron-left"></i> <span> Back </span>
                                    </Link>
                                </div>
                                :''}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SetterView;