import React, { useEffect, useState } from 'react'
import { deleteUser, getSettersList, activeInUser } from '../../api/apiHandler';
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import { bodyEncryption } from '../common/RedirectPathMange';

import FeatherIcon from "feather-icons-react";
// import Tooltip from '@material-ui/core/Tooltip';
import Tooltip from '@mui/material/Tooltip';
// import IconButton from '@material-ui/core/IconButton';
import IconButton from '@mui/material/IconButton';
import Swal from 'sweetalert2'
import { renderToStaticMarkup } from 'react-dom/server'
import { useNavigate } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import { db } from "../../config/firebase";
import { collection,doc,query,where,getDocs,writeBatch,serverTimestamp} from "firebase/firestore";
import { Modal } from 'react-bootstrap';

function SettersList() {
    const navigate = useNavigate();
    const [settersList, setSettersList] = useState([]);
    // console.log('settersList: ', settersList);
    const [totalRecordCount, setTotalRecordCount] = useState(0)
    const [pageNo, setPageNo] = useState(1);
    const [searchQuery, setSearchQuery] = useState('');
    const [loader, setLoader] = useState(true);
    const [message, setMessage] = useState("");
    const [showModal, setShowModal] = useState(false);
    const itemsPerPage = 10;

    useEffect(() => {

        if(searchQuery === ''){
            Swal.fire({
                title: 'Please wait...',
                didOpen: () => {
                    Swal.showLoading();
                }
            });
        }
        getSettersList({ "page": pageNo, "record_count": parseInt(process.env.REACT_APP_RECORD_COUNT), 'search_text': searchQuery }).then((response) => {
            response = JSON.parse(response)
            
            if (response.code === 200) {
                // console.log(response)
                Swal.close();
                setLoader(false);
                setSettersList(response.data.users)
                setTotalRecordCount(Math.ceil(response.data.total_record_count / process.env.REACT_APP_RECORD_COUNT))
            } else {
                Swal.close();
                setLoader(false);
                setSettersList([]);
            }
        })

        // Swal.close();
    }, [searchQuery, pageNo]);


    const handlePageClick = (event) => {
        setPageNo(event.selected + 1)
    };

    // const viewFeedbackDetails = (item) => {
    //     var encId = encodeURIComponent(bodyEncryption((item.id).toString()))
    //     navigate(`/feedback/${encId}`);
    // }

    const viewUserDetails = (item) => {
        var encId = encodeURIComponent(bodyEncryption((item.id).toString()))
        navigate(`/setterView/${encId}`);
    }

    const showMessage = (msg) => {
        Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: msg,
            toast: true,
            showConfirmButton: false,
            timer: 1500
        })
    }

    function deleteUserIdWise(userId) {

        Swal.fire({
            title: 'Are you sure you want to delete?',
            text: "You won't be able to revert this!",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#02C9D6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                // console.log("userId " + userId)
                deleteUser({ "user_id": userId }).then((response) => {
                    response = JSON.parse(response)
                    console.log(response,"bbcccbcb")
                    if (response.code === 200) {
                        // $('#mangeUser').DataTable().row("#userId" + userId).remove().draw(false);
                        showMessage(response.message);
                        // Update user list after deletion
                        // setSettersList(prevUsersList => prevUsersList.filter(user => user.id !== userId));
                        getSettersList({ "page": pageNo, "record_count": parseInt(process.env.REACT_APP_RECORD_COUNT), 'search_text': searchQuery }).then((response) => {
                            response = JSON.parse(response)
                            if (response.code === 200) {
                                // console.log(response)
                                setSettersList(response.data.users)
                                setTotalRecordCount(Math.ceil(response.data.total_record_count / process.env.REACT_APP_RECORD_COUNT))
                            } else {
                                setSettersList([]);
                            }
                        })
                    }
                });
            }
        })
    }

    const handleSearch = (event) => {
        const query = event.target.value;
        setSearchQuery(query);
    }

    function changeStatus(userId, item, position) {

        var status = (item.is_active === 1) ? 0 : 1

        activeInUser({ "id": userId }).then((response) => {
            response = JSON.parse(response)
            if (response.code === 200) {
                showMessage(response.message)
                settersList[position].is_active = status
                var data = renderToStaticMarkup(renderStatusUiText(settersList[position])).replace("MuiBox-root css-4u9rcm", "MuiBox-root css-k0gowo").replace("MuiBox-root css-1oewkz4", "MuiBox-root css-lv896x")
                // $('#mangeUser').DataTable().row("#userId" + userId).cell("#userStatus" + userId).data(data).draw(false);
                getSettersList({ "page": pageNo, "record_count": parseInt(process.env.REACT_APP_RECORD_COUNT), 'search_text': searchQuery }).then((response) => {
                    response = JSON.parse(response)
                    if (response.code === 200) {
                        // console.log(response)
                        setSettersList(response.data.users)
                        setTotalRecordCount(Math.ceil(response.data.total_record_count / process.env.REACT_APP_RECORD_COUNT))
                    } else {
                        setSettersList([]);
                    } 
                })
            }
        });
    }

    function renderStatusUiText(item) {
        return (
            item.is_active === 1 ? <span style={{ color: 'green' }}>Active</span> : <span style={{ color: 'red' }}>Inactive</span>
        )
    }

    const handleChatHistory = (user_chat_data) => {
        // console.log('user_chat_data: ', user_chat_data);
        const chatUserData = {
            receiver_username: user_chat_data.username,
            receiver_id: user_chat_data.id,
            receiver_firstname: user_chat_data.firstname,
            receiver_image: user_chat_data.profile_picture,
            receiver_lastname: user_chat_data.lastname,
            isActive: true,
        }
        navigate('/chat',{ state : chatUserData })
    }

    
    const sendBulkMessage = async (message_type,image_file) => {
        try {
            Swal.fire({
                title: 'Please wait...',
                didOpen: () => {
                    Swal.showLoading();
                }
            });
            handleCloseModal()
            const batch = writeBatch(db);
                
            for (const user of settersList) {
                let recentChatId = `@admin@${user.id}@`;
                const sendMessageObj = {
                    timestamp: serverTimestamp(),
                    participants: [user.id, "A1"],
                    media: message_type === "image" ? `${process.env.REACT_APP_CHAT_IMAGES_AWS_S3_URL}${recentChatId}/${image_file}` : "",
                    message: message_type === "normal" ? message : "",
                    message_type: message_type,
                    sender_device_token: "",
                    sender_username: "Admin",
                    sender_image: "",
                    sender_device_type: "",
                    sender_lastname: "Admin",
                    sender_firstname: "Admin",
                    sender_id: "A1",
                    receiver_username: user.username,
                    receiver_id: user.id,
                    recent_chat_id: recentChatId,
                    receiver_firstname: user.firstname,
                    receiver_image: user.profile_picture,
                    receiver_lastname: user.lastname,
                    chat_time: "",
                    chat_read: false
                };
        
                const q = query(
                    collection(db, process.env.REACT_APP_FIREBASE_TABLE_CHAT),
                    where("recent_chat_id", "==", recentChatId),
                    where("chat_read", "==", false),
                    where("receiver_id", "==", user.id)
                );
    
                const querySnapshot = await getDocs(q);
                const unreadCount = querySnapshot.size + 1;
    
                sendMessageObj.chat_count = unreadCount.toString();

                // Add the message to the main chat collection
                const userChatDocRef = doc(collection(db, process.env.REACT_APP_FIREBASE_TABLE_CHAT));
                batch.set(userChatDocRef, sendMessageObj);
                setMessage("");

                // Update the last message in a separate document within the recent chat collection
                const lastMessageDocRef = doc(db, process.env.REACT_APP_FIREBASE_TABLE_RECENT_CHAT, recentChatId);
                batch.set(lastMessageDocRef, sendMessageObj);
            }
        
            await batch.commit();
            console.log('Batch messages sent successfully!');
            Swal.close();
        } catch (error) {
            handleCloseModal()
            Swal.close();
            console.error('Error sending batch messages: ', error);
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: error.message,
                toast: true,
                showConfirmButton: false,
                timer: 2000
            });
        }
    };

    const handleOpenModal = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleKeyDown = async (e) => {
        if (e.key === 'Enter' && message.trim() !== '') {
            e.preventDefault();
           await sendBulkMessage("normal","");
        }
    };

    return (
        <>
            <main>
                <div className='content-page mt-3'>
                    <div className='content'>
                        <div className='container-fluid'>
                            <div className='cal'>
                                <div className="col-sm-12">
                                    <div className="page-title-box d-flex justify-content-between">
                                        <h4 className="page-title">Setter Users</h4>
                                        <button className="btn btn-outline-primary" onClick={handleOpenModal}>Message</button>
                                    </div>
                                </div>
                                <div className="search-bar">
                                    <input
                                        type="text"
                                        placeholder="Search..."
                                        value={searchQuery}
                                        onChange={handleSearch}
                                    />
                                </div>
                                {loader === false ?
                                <>
                                    <div className="table-responsive">
                                        <table id="mangeUser" className="table table-hover dataTable js-exportable">
                                            <thead>
                                                <tr>
                                                    <th>ID</th>
                                                    <th>Username</th>
                                                    <th>Name</th>
                                                    <th>Email</th>
                                                    <th>Phone Number</th>
                                                    <th>Status</th>
                                                    <th>Status Action</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            {settersList.length > 0 ? (
                                            <tbody>
                                                {
                                                    settersList && settersList.map((item, index) => {
                                                    const displayIndex = ((pageNo * itemsPerPage) - 10) + index + 1;
                                                        return (
                                                            <tr id={item.id} key={index}>
                                                                <td >
                                                                    {displayIndex}
                                                                    {/* {index+1} */}
                                                                </td>
                                                                <td >
                                                                    {item.username}
                                                                </td>
                                                                <td >
                                                                    {item.full_name}
                                                                </td>
                                                                <td >
                                                                    {item.email}
                                                                </td>
                                                                <td >
                                                                    {item.country_code + " " + item.phone_number}
                                                                </td>
                                                                {/* <td >
                                                                    {item.is_active === '0' ? <b><span style={{ "color": "Red" }}>Inactive</span></b> : <b><span style={{ "color": "Green" }}>Active</span></b>}
                                                                </td> */}
                                                                <td id={"userStatus" + item.id}>
                                                                    {renderStatusUiText(item)}
                                                                </td>
                                                                <td id={"userStatus" + item.id}>
                                                                    <Tooltip title="Change Status">
                                                                        <IconButton onClick={() => { changeStatus(item.id, item, index) }}>
                                                                            <FeatherIcon icon="repeat" width="18" />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </td>
                                                                <td >
                                                                    
                                                                    {/* <button type="submit" className="Send"><i className="fa-solid fa-paper-plane"></i></button> */}
                                                                
                                                                    <Tooltip title="Chat">
                                                                        <IconButton onClick={() => { handleChatHistory(item) }}>
                                                                            <FeatherIcon icon="send" width="18" />
                                                                        </IconButton>
                                                                    </Tooltip>

                                                                    <Tooltip title="View">
                                                                        <IconButton onClick={() => { viewUserDetails(item) }}>
                                                                            <FeatherIcon icon="eye" width="18" />
                                                                        </IconButton>
                                                                    </Tooltip>

                                                                    {/* <Tooltip title="Feedback">
                                                                        <IconButton onClick={() => { viewFeedbackDetails(item) }}>
                                                                            <FeatherIcon icon="eye" width="18" />
                                                                        </IconButton>
                                                                    </Tooltip> */}

                                                                    <Tooltip title="Delete">
                                                                        <IconButton onClick={() => { deleteUserIdWise(item.id) }}>
                                                                            <FeatherIcon icon="trash-2" width="18" color="red" />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                    
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                            ) : (
                                                settersList.length === 0 && <h3 className='mt-3'>No User data available.</h3>                                )}
                                        </table>
                                    </div>
                                    <ReactPaginate
                                        breakLabel="..."
                                        nextLabel=" > "
                                        onPageChange={handlePageClick}
                                        // pageRangeDisplayed={pageCount}
                                        pageCount={totalRecordCount}
                                        previousLabel=" < "
                                        renderOnZeroPageCount={null}
                                        containerClassName="pagination"
                                        pageLinkClassName="page-link"
                                        previousLinkClassName="page-link"
                                        nextLinkClassName="page-link"
                                        disabledClassName="disabled"
                                        activeClassName="active"
                                        activeLinkClassName="active-link"
                                    />
                                </>
                                :''}
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <Modal show={showModal} onHide={handleCloseModal} centered>
                <Modal.Header>
                    <Modal.Title>Message</Modal.Title>
                    <Tooltip title="Close">
                        <IconButton onClick={handleCloseModal}>
                            <FeatherIcon icon="x-square" width="35" />
                        </IconButton>
                    </Tooltip>
                </Modal.Header>
                <Modal.Body>
                <div className="chatbody">
                    <div className="chat-main">
                        <div id="MessageForm">
                            <div className="chat-input-group">
                                <input type="text" placeholder="Enter Message..." id="MessageInput" value={message} onChange={(e)=>setMessage(e.target.value)} onKeyDown={handleKeyDown} style={{borderRadius:"10px"}} />
                                {/* <span className="input-group-text p-0" id="basic-addon2">
                                    <label for="formFile" className="form-label mb-0" style={{cursor:"pointer"}}> 
                                    <i className="fa-solid fa-paperclip font_18" style={{cursor:"pointer"}}></i></label>
                                    <input className="form-control d-none" type="file" id="formFile" accept="image/*" />
                                </span> */}
                            </div>
                            <button type="submit" className="Send" onClick={()=>sendBulkMessage("normal","")} disabled={message.trim() === ''}><i className="fa-solid fa-paper-plane"></i></button>
                        </div>
                    </div>
                </div>
                </Modal.Body>
            </Modal>
        </>

    );
}

export default SettersList;