import React, { useEffect, useState } from 'react';
import { dashboard, getClosersList, getEarningList, getLeadList, getSettersList, getUsersList } from '../../api/apiHandler';
import { Helmet } from 'react-helmet';
import Sidebar from '../../components/sidebar/Sidebar';
import Footer from '../../components/footer/Footer';
import Header from '../../components/header/Header';

function Dashboard() {
    
    const [setterCount, setSetterCount] = useState(0)
    const [closerCount, setCloserCount] = useState(0)
    const [leadCount, setLeadCount] = useState(0)
    const [soldLeadCount, setSoldLeadCount] = useState(0)
    const [unsoldLeadCount, setUnsoldLeadCount] = useState(0)
    const [earningAmount, setEarningAmount] = useState(0)
    const [pageNo, setPageNo] = useState(1);
    const [searchQuery, setSearchQuery] = useState('');

    useEffect(() => {
        // console.log(localStorage.getItem("Atoken"))
        // getUsersList({ "page": pageNo, "record_count": parseInt(process.env.REACT_APP_RECORD_COUNT), 'search_text': searchQuery }).then((response)=>{
        //     response = JSON.parse(response)

        //     if(response.code == 200){ 
        //         setCustomerCount(response.data.total_record_count)
        //         // setRenterCount(response.data.renter_count)
        //     }
        // })

        
        getSettersList({ "page": pageNo, "record_count": parseInt(process.env.REACT_APP_RECORD_COUNT), 'search_text': searchQuery }).then((response)=>{
            response = JSON.parse(response)

            if(response.code == 200){ 
                setSetterCount(response.data.total_record_count)
                // setRenterCount(response.data.renter_count)
            }
        })

        getClosersList({ "page": pageNo, "record_count": parseInt(process.env.REACT_APP_RECORD_COUNT), 'search_text': searchQuery }).then((response)=>{
            response = JSON.parse(response)

            if(response.code == 200){ 
                setCloserCount(response.data.total_record_count)
                // setRenterCount(response.data.renter_count)
            }
        })

        getLeadList({ "page": pageNo, "record_count": parseInt(process.env.REACT_APP_RECORD_COUNT), 'search_text': searchQuery, "lead_id":'' }).then((response)=>{
            response = JSON.parse(response)

            if(response.code == 200){ 
                setLeadCount(response.data.total_record_count)
                setSoldLeadCount(response.data.total_sold_lead_count)
                setUnsoldLeadCount(response.data.total_unsold_lead_count)
                // setRenterCount(response.data.renter_count)
            }
        })

        getEarningList({ "page": pageNo, "record_count": parseInt(process.env.REACT_APP_RECORD_COUNT), 'search_text': searchQuery }).then((response)=>{
            response = JSON.parse(response)

            if(response.code == 200){ 
                setEarningAmount(response.data.total_earning_amount)
            }
        })

    }, [])


    return (
        <>
            <main>
                <div className='content-page'>
                    <div className='content'>
                        <div className='container-fluid'>
                            <div>
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="page-title-box">
                                            <h4 className="page-title">Dashboard</h4>
                                            <ol className="breadcrumb">
                                                <li className="breadcrumb-item">
                                                    Welcome to TLM Dashboard
                                                </li>
                                            </ol>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">

                                    <div className="col-xl-3 col-md-6">
                                        <div className="card mini-stat bg-primary">
                                            <div className="card-body mini-stat-img" style={{height:"9rem"}} >
                                                <div className="mini-stat-icon">
                                                    <i className="mdi mdi-cube-outline float-right"></i>
                                                </div>
                                                <div className="text-white">
                                                    <h6 className="text-uppercase mb-3">Total Setter</h6>
                                                    {/* <h6 className="mb-3">Total Setters</h6> */}
                                                    <h4 className="mb-4">{setterCount}</h4>
                                                    {/* <h4 className="mb-4">0</h4> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-xl-3 col-md-6">
                                        <div className="card mini-stat bg-primary">
                                            <div className="card-body mini-stat-img" style={{height:"9rem"}}>
                                                <div className="mini-stat-icon">
                                                    <i className="mdi mdi-cube-outline float-right"></i>
                                                </div>
                                                <div className="text-white">
                                                    <h6 className="text-uppercase mb-3">Total Closers</h6>
                                                    {/* <h6 className="mb-3">Total Closers</h6> */}
                                                    <h4 className="mb-4">{closerCount}</h4>
                                                    {/* <h4 className="mb-4">0</h4> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-xl-3 col-md-6">
                                        <div className="card mini-stat bg-primary">
                                            <div className="card-body mini-stat-img" style={{height:"9rem"}}>
                                                <div className="mini-stat-icon">
                                                    <i className="mdi mdi-cube-outline float-right"></i>
                                                </div>
                                                <div className="text-white">
                                                    <h6 className="text-uppercase mb-3">Total Leads</h6>
                                                    {/* <h6 className="mb-3">Total Leads</h6> */}
                                                    <h4 className="mb-4">{leadCount}</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-xl-3 col-md-6">
                                        <div className="card mini-stat bg-primary">
                                            <div className="card-body mini-stat-img" style={{height:"9rem"}}>
                                                <div className="mini-stat-icon">
                                                    <i className="mdi mdi-cube-outline float-right"></i>
                                                </div>
                                                <div className="text-white">
                                                    <h6 className="text-uppercase mb-3">Total Sold/unsold</h6>
                                                    {/* <h6 className="mb-3">Total Sold/unsold</h6> */}
                                                    <h4 className="mb-4">{soldLeadCount + '/' + unsoldLeadCount}</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-xl-3 col-md-6">
                                        <div className="card mini-stat bg-primary">
                                            <div className="card-body mini-stat-img" style={{height:"9rem"}}>
                                                <div className="mini-stat-icon">
                                                    <i className="mdi mdi-cube-outline float-right"></i>
                                                </div>
                                                <div className="text-white">
                                                    <h6 className="text-uppercase mb-3">Total Earnings</h6>
                                                    {/* <h6 className="mb-3">Total Earnings</h6> */}
                                                    <h4 className="mb-4">${earningAmount.toFixed(2)}</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
}
export default Dashboard;