import React, { useEffect, useState, useRef } from 'react'
import { getEarningList } from '../../api/apiHandler';
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";
import 'daterangepicker';
import moment from 'moment';


import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom';
import ReactPaginate from 'react-paginate';

import { bodyEncryption } from '../common/RedirectPathMange';
import FeatherIcon from "feather-icons-react";
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';


function LeadsList() {
    const navigate = useNavigate();
    const [leadsList, setLeadsList] = useState([]);
    const [totalRecordCount, setTotalRecordCount] = useState(0)
    const [totalEarning, setTotalEarning] = useState(0)
    const [pageNo, setPageNo] = useState(1);
    const [searchQuery, setSearchQuery] = useState('');
    const [loader, setLoader] = useState(true);
    const itemsPerPage = 10;
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const inputRef = useRef(null);

    useEffect(() => {
        if (searchQuery === '') {
            Swal.fire({
                title: 'Please wait...',
                didOpen: () => {
                    Swal.showLoading();
                }
            });
        }

        // getEarningList({ "page": pageNo, "record_count": parseInt(process.env.REACT_APP_RECORD_COUNT), 'search_text': searchQuery, ...(startDate!='') &&{'startDate':startDate}, ...(endDate!='') &&{'endDate':endDate} }).then((response) => {
            getEarningList({ "page": pageNo, "record_count": parseInt(process.env.REACT_APP_RECORD_COUNT), 'search_text': searchQuery, ...(startDate!=='') &&{'startDate':startDate}, ...(endDate!=='') &&{'endDate':endDate} }).then((response) => {
            response = JSON.parse(response)
            if (response.code === 200) {
                Swal.close();
                setLoader(false);
                setLeadsList(response.data.laedHistoryList)
                setTotalRecordCount(Math.ceil(response.data.total_record_count / process.env.REACT_APP_RECORD_COUNT))
                setTotalEarning(response.data.total_earning_amount)
            } else {
                Swal.close();
                setLoader(false);
                setLeadsList([]);
            }
        })

        // Swal.close();
    }, [searchQuery, pageNo,startDate, endDate]);


    // Initialize DateRangePicker after component mount
    // useEffect(() => {
    //     $(inputRef.current).daterangepicker({
    //         opens: 'left'
    //     }, function (start, end, label) {
    //         setStartDate(start.format('YYYY-MM-DD'))
    //         setEndDate(end.format('YYYY-MM-DD'))
    //         console.log("A new date selection was made: " + start.format('YYYY-MM-DD') + ' to ' + end.format('YYYY-MM-DD'));
    //     });
    // }, []);
    useEffect(() => {
        $(inputRef.current).daterangepicker({
          opens: 'left',
          
          maxDate: moment().startOf('day'),
          autoUpdateInput: false,
          locale: {
            format: 'YYYY-MM-DD',
            cancelLabel: 'Clear'
          }
        }, function(start, end, label) {
          setStartDate(start.format('YYYY-MM-DD'));
          setEndDate(end.format('YYYY-MM-DD'));
          console.log("A new date selection was made: " + start.format('YYYY-MM-DD') + ' to ' + end.format('YYYY-MM-DD'));
          $(inputRef.current).val(start.format('YYYY-MM-DD') + ' to ' + end.format('YYYY-MM-DD'));
          
        });
    
        // // Clear the inputs when the date range is cleared
        $(inputRef.current).on('cancel.daterangepicker', function(ev, picker) {
          $(this).val('');
          setStartDate('');
          setEndDate('');
        });
      }, []);


    const handlePageClick = (event) => {
        setPageNo(event.selected + 1)
    };

    const handleSearch = (event) => {
        const query = event.target.value;
        setSearchQuery(query);
    }

    const viewLeadDetails = (item) => {
        var encId = encodeURIComponent(bodyEncryption((item.id).toString()))
        navigate(`/lead-details/${encId}`);
    }

    return (

        <>
            <main>
                <div className='content-page mt-3'>
                    <div className='content'>
                        <div className='container-fluid'>
                            <div className='cal row'>
                                <div className="col-sm-12">
                                    <div className="page-title-box">
                                        <h4 className="page-title">Earning List</h4>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-12">
                                       
                                         <div className="search-bar">
                                         <label><b>Total Earning: ${totalEarning.toFixed(2)}</b></label>
                                </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-12">
                                       
                                         <div className="search-bar">
                                         <input
                                            type="text"
                                            name="daterange"
                                            className="form-control"
                                            ref={inputRef}
                                            placeholder='Select date range'
                                        />
                                </div>
                                    
                                </div>
                                <div className="col-lg-6 col-md-6 col-12">
                                    
                                <div className="search-bar">
                                    <input
                                        type="text"
                                        placeholder="Search..."
                                        value={searchQuery}
                                        onChange={handleSearch}
                                    />
                                </div>
                                    
                                </div>
                                
                                {loader === false ?
                                <>
                                    <div className="table-responsive">
                                        <table id="mangeUser" className="table table-hover dataTable js-exportable">
                                            <thead>
                                                <tr>
                                                    <th>ID</th>
                                                    <th>Setter Name</th>
                                                    <th>Lead Name</th>
                                                    <th>Industry</th>
                                                    <th>Admin Commission</th>
                                                    <th>Admin Earning</th>
                                                    <th>Setter Earning</th>
                                                    <th>Sold Amount</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>

                                            {leadsList.length > 0 ? (
                                                <tbody>
                                                    {
                                                        leadsList && leadsList.map((item, index) => {
                                                            const displayIndex = ((pageNo * itemsPerPage) - 10) + index + 1;
                                                            return (
                                                                <tr id={item.id} key={index}>
                                                                    <td >
                                                                        {displayIndex}
                                                                    </td>

                                                                    <td>
                                                                    {item.setter_data.firstname+' '+item.setter_data.lastname}

                                                                    </td>
                                                                    <td>
                                                                    {item.name}
                                                                    </td>
                                                                    <td>
                                                                    {item.industry}
                                                                    </td>
                                                                    <td>
                                                                    {item.admin_commission+'%'}
                                                                    </td>

                                                                    <td>
                                                                    ${item.commission_amount}
                                                                    </td>
                                                                    <td >
                                                                        ${item.setter_earning_amount}
                                                                    </td>
                                                                    <td >
                                                                        ${item.sold_amount}
                                                                    </td>
                                                                    <td >
                                                                        <Tooltip title="View">
                                                                            <IconButton onClick={() => { viewLeadDetails(item) }}>
                                                                                <FeatherIcon icon="eye" width="18" />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                    </td>

                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            ) : (
                                                leadsList.length === 0 && <h3 className='mt-3'>No Lead data available.</h3>)}

                                        </table>
                                    </div>
                                    <ReactPaginate
                                        breakLabel="..."
                                        nextLabel=" > "
                                        onPageChange={handlePageClick}
                                        // pageRangeDisplayed={pageCount}
                                        pageCount={totalRecordCount}
                                        previousLabel=" < "
                                        renderOnZeroPageCount={null}
                                        containerClassName="pagination"
                                        pageLinkClassName="page-link"
                                        previousLinkClassName="page-link"
                                        nextLinkClassName="page-link"
                                        disabledClassName="disabled"
                                        activeClassName="active"
                                        activeLinkClassName="active-link"
                                    />
                                </>
                                : ''}
                            </div>
                                
                        </div>
                    </div>
                </div >
            </main>
        </>

    );
}

export default LeadsList;