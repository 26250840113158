import React, { useEffect, useState } from 'react'
import { getUsersList } from '../../api/apiHandler';
import $ from "jquery";
import FeatherIcon from "feather-icons-react";
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Swal from 'sweetalert2'
import { renderToStaticMarkup } from 'react-dom/server'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { bodyDecryption } from '../common/RedirectPathMange';

function UserView() {
    let { id } = useParams();
    var decId = decodeURIComponent(bodyDecryption(id))

    const [userDetails, setUserDetails] = useState(null)
    const [pageNo, setPageNo] = useState(1);

    useEffect(() => {

        if (id != undefined) {
            getUsersList({ "user_id": decId,"page": pageNo, "record_count": parseInt(process.env.REACT_APP_RECORD_COUNT) }).then((res) => {
                res = JSON.parse(res)
                if (res.code == 200) {
                    // console.log("hello",res.data)
                    // console.log("---hello",res.data.users[0].email)
                    setUserDetails(res.data.users[0])
                } else {
                    setUserDetails([])
                }
            });
        }
    }, [id])
    
    if (userDetails == null) return <> </>

    return (
        <>
            <div className='content-page mt-5'>
                <div className='content'>
                    <div className='container-fluid'>
                        <div className='cal'>
                            <div className="row clearfix d-flex justify-content-center text-center" >
                                <div className="col-lg-9 col-md-12">
                                    <div className="header l-slategray">
                                        <h4 className="m-t-10">{userDetails.firstname} {userDetails.lastname}</h4>
                                    </div>
                                    <div className="member-img">
                                        <img src='https://www.pngitem.com/pimgs/m/508-5087336_person-man-user-account-profile-employee-profile-template.png' className="rounded-circle" alt="profile-image" style={{ width: 200, height: 200 }} />
                                        {/* <img src={userDetails.profile_picture != undefined && userDetails.profile_picture != null ? userDetails.profile_picture : 'https://www.pngitem.com/pimgs/m/508-5087336_person-man-user-account-profile-employee-profile-template.png'} className="rounded-circle" alt="profile-image" style={{width: 200, height: 200}} /> */}
                                    </div>
                                    <div className="body">
                                        <hr />
                                        <div className="row">
                                        <div className="col-4">
                                                <h5>Username</h5>
                                                <p style={{ fontSize: '15px' }}>{userDetails.username}</p>
                                            </div>
                                            <div className="col-4">
                                                <h5>Email</h5>
                                                <p style={{ fontSize: '15px' }}>{userDetails.email}</p>
                                            </div>
                                            <div className="col-4">
                                                <h5>Phone Number</h5>
                                                <p style={{ fontSize: '15px' }}>{userDetails.country_code} {userDetails.phone_number}</p>
                                            </div>
                                        </div>
                                        <hr />
                                        {/* <div className="row">
                                            <div className="col-4">
                                                <h5>Id Proof</h5>
                                                <img src={userDetails.id_proof} alt="id-proof-image" style={{ width: 250, height: 300 }} />
                                            </div>
                                            <div className="col-4">
                                                <h5>Driving License</h5>
                                                <img src={userDetails.driving_license_image} alt="driving-license-image" style={{ width: 250, height: 300 }} />
                                            </div>
                                            <div className="col-4">
                                                <h5>Tax Document</h5>
                                                <img src={userDetails.tax_document} alt="tax-document-image" style={{ width: 250, height: 300 }} />
                                            </div>
                                        </div>
                                        <hr /> */}
                                        {/* <div className="row">
                                            <div className="col-4">
                                                <h5>Rental Aggrement</h5>
                                                <img src={userDetails.rental_aggrement} alt="rental-aggrement-image" style={{ width: 250, height: 300 }} />
                                            </div>
                                            <div className="col-4">
                                                <h5>Utility Bill</h5>
                                                <img src={userDetails.utility_bill} alt="utility-bill-image" style={{ width: 250, height: 300 }} />
                                            </div>
                                        </div>
                                        <hr /> */}
                                        <div className="row">
                                            {/* <div className="col-4">
                                                <h5>Email Verification</h5>
                                                <p style={{ fontSize: '15px' }}>{userDetails.email_verification == '0' ? 'No' : 'Yes'}</p>
                                            </div> */}
                                            <div className="col-4">
                                                <h5>Phone Verification</h5>
                                                <p style={{ fontSize: '15px' }}>{userDetails.is_verified == '0' ? 'No' : 'Yes'}</p>
                                            </div>
                                            <div className="col-4">
                                                <h5>Status</h5>
                                                <p style={{ fontSize: '14px' }}>{userDetails.is_active == '0' ? "🔴 Inactive" : "🟢 Active"}</p>
                                            </div>
                                        </div>
                                        <br /><br /><br />
                                    </div>
                                    <Link to='/closersList' className="btn btn-primary btn-lg common_hover">
                                        <i className="mdi mdi-chevron-left"></i> <span> back </span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default UserView;